html,
body {
  margin: 0;
  padding: 0;
  /* background-color: #fff; */
}

.home__container {
  background: url(../fontawesome/image/backgroundNotify.png);
  background-size: 100%;
}
.home__container-head-notify {
  background: url(../fontawesome/image/icon_notify.svg);
  background-repeat: no-repeat;
}
.home__container-head-logoname {
  background: url(../fontawesome/image/logo_png.svg);
  background-repeat: no-repeat;
}
.container__list-product::-webkit-scrollbar {
  display: none;
}
.navbar__home {
  box-shadow: 6px 8px 12px 10px rgba(74, 74, 75, 0.5);
}
.shadow-campaign-top {
  box-shadow: 1px 3px 6px rgba(20, 63, 194, 0.3);
  border-radius: 12px;
}
.shadown-faq {
  box-shadow: 2px 7px 0px 5px rgba(20, 63, 194, 0.3);
  border-radius: 12px;
}
.shadow-border {
  box-shadow: 1px 4px 12px 0px rgb(148, 148, 148);
}
.border-grid {
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  border-radius: 10px;
  display: flex;
  justify-items: center;
  height: 90px;
}
.border-iconnext {
  background-color: #e0f0f7;
  height: 60px;
  width: 60px;
  border-radius: 60px;
}
.navbar__home-Iconhome {
  background: url(../fontawesome/image/home_icon.svg);
  background-repeat: no-repeat;
}
.navbar__home-Icontake {
  background: url(../fontawesome/image/takephoto_icon.svg);
}
.navbar__home-Icongift {
  background: url(../fontawesome/image/gift_icon.svg);
}
.navbar__home-Iconprofile {
  background: url(../fontawesome/image/profile_icon.svg);
}
.icon-navbar {
  width: 80px;
  height: 46px;
}
.hscroll {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 130px;
  white-space: nowrap;
}
.menu-bar {
  margin-top: 10px;
  text-align: center;
  color: #333333;
  /* height: 70px; */
  font-size: 13px;
}
@media (max-width: 320px) {
  .menu-home {
    margin-top: 245px;
  }
}
@media (min-width: 500px) and (max-width: 799px) {
  .menu-home {
    margin-top: 350px;
  }
  .campaign-top-height {
    max-height: 230px;
  }
}
@media (min-width: 800px) {
  .menu-home {
    margin-top: 400px;
  }
}
.background-menu {
  border-radius: 16px;
  /* justify-content: space-around; */
  cursor: pointer;
}

.container__list-product-time {
  text-align: left;
  width: 100%;
  height: 16px;
}

.icon-nav-home {
  margin-left: 0;
  background-repeat: no-repeat;
}
